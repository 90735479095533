<template>
    <v-layout>
        <v-flex>
            <v-card class="elevation-0 transparent mb-5">
                <v-layout align-center justify-center>
                    <v-flex sm8 md6 mx-4>

                        <v-card-title class="justify-center">
                            <div class="display-1">{{ $t('contact.title') }}</div>
                        </v-card-title>

                        <v-form @submit.prevent="submit" ref="form" lazy-validation>
                            <!-- Name -->
                            <v-text-field
                                    id="name"
                                    name="name"
                                    :label="$t('contact.form.name')"
                                    v-model="form.name"
                                    required
                                    :rules="nameRules"></v-text-field>

                            <!-- Email -->
                            <v-text-field
                                    id="email"
                                    name="email"
                                    :label="$t('contact.form.email')"
                                    v-model="form.email"
                                    required
                                    :rules="emailRules"></v-text-field>

                            <!-- Subject -->
                            <v-text-field
                                    id="subject"
                                    name="subject"
                                    :label="$t('contact.form.subject')"
                                    v-model="form.subject"
                                    required
                                    :rules="subjectRules"></v-text-field>

                            <!-- Message -->
                            <v-textarea
                                    id="message"
                                    name="message"
                                    :label="$t('contact.form.message')"
                                    v-model="form.message"
                                    required
                                    :rules="messageRules"></v-textarea>

                            <!-- Human check operation -->
                            <v-text-field
                                    id="humanCheckOp"
                                    name="humanCheckOp"
                                    :label="$t('contact.form.humanCheckOp')"
                                    v-model="form.humanCheckOp"
                                    required
                                    :rules="humanCheckOpRules"></v-text-field>

                            <!-- Actions -->

                            <!-- Send Button -->
                            <v-btn class="mt-3" block outline type="submit" :disabled="isSending">{{
                                $t('contact.form.send') }}
                            </v-btn>

                            <!-- Contact details -->
                            <v-list class="transparent mt-4">

                                <!-- Email -->
                                <v-list-tile>
                                    <v-list-tile-action>
                                        <v-icon>email</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title>contact(at)arthurvimond.fr</v-list-tile-title>
                                    </v-list-tile-content>
                                </v-list-tile>

                                <!-- Phone -->
                                <a href="tel:0662488939">
                                    <v-list-tile class="accent--text">
                                        <v-list-tile-action>
                                            <v-icon>phone</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title>O6 62 48 89 39</v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                </a>

                                <!-- Address -->
                                <v-list-tile>
                                    <v-list-tile-action>
                                        <v-icon>place</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title>Rennes, France</v-list-tile-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </v-list>
                        </v-form>

                    </v-flex>
                </v-layout>
            </v-card>

            <!-- Loading -->
            <v-dialog v-model="isSending" persistent max-width="400px">
                <v-card class="pa-3">
                    <v-card-text>
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        <span class="ml-3 mb-2 text-xs-center">{{ $t('dialog.sending_message') }}</span>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- Snackbar -->
            <v-snackbar v-model="showSnackbar"> {{ snackbarMessage }}</v-snackbar>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: 'Contact',
        created() {
            this.windowHeight = window.innerHeight
        },
        props: {},
        data() {
            const defaultForm = Object.freeze({
                name: '',
                email: '',
                subject: '',
                message: '',
                humanCheckOp: ''
            })

            return {
                nameRules: [
                    v => !!v || this.$t('error.required'),
                    v => v.length <= 50 || this.$t('error.max_x_characters', [50])
                ],
                emailRules: [
                    v => {
                        return !!v || this.$t('error.required')
                    },
                    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('error.email_invalid')
                ],
                subjectRules: [
                    v => !!v || this.$t('error.required'),
                    v => v.length <= 100 || this.$t('error.max_x_characters', [100])
                ],
                messageRules: [
                    v => !!v || this.$t('error.required'),
                    v => v.length <= 2000 || this.$t('error.max_x_characters', [2000])
                ],
                humanCheckOpRules: [
                    v => !!v || this.$t('error.required'),
                    v => v.length <= 100 || this.$t('error.max_x_characters', [100])
                ],
                form: Object.assign({}, defaultForm),
                defaultForm,
                isSending: false,
                snackbarMessage: '',
                showSnackbar: false
            }
        },
        methods: {
            submit() {

                // Prevent invalid form to be sent
                if (!this.$refs.form.validate()) {
                    return
                }

                // Human check operation
                if (this.form.humanCheckOp != 4) {
                    return
                }

                // Create body
                let body = {
                    name: this.form.name,
                    email: this.form.email,
                    subject: this.form.subject,
                    message: this.form.message,
                    humanCheckOp: this.form.humanCheckOp
                }
                // Hide send button
                this.isSending = true

                // Send email request
                this.$http.post('https://us-central1-arthur-vimond.cloudfunctions.net/sendEmail', body)
                    .then(response => {
                        // Success callback
                        this.snackbarMessage = this.$t('success.message_sent')
                        this.showSnackbar = true

                        this.resetForm()

                        // Show send button
                        this.isSending = false
                    }, response => {
                        // Error callback
                        this.snackbarMessage = this.$t('error.message_not_sent')
                        this.showSnackbar = true

                        // Show send button
                        this.isSending = false
                    })
            },
            resetForm() {
                this.form.name = ''
                this.form.email = ''
                this.form.subject = ''
                this.form.message = ''
                this.form.humanCheckOp = ''
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    a:link {
        text-decoration: none;
    }


</style>
