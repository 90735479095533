<template>
    <v-app dark class="background">
        <v-toolbar app class="secondary">
            <router-link to="/#showcase">
                <v-toolbar-title class="white--text" v-text="title"/>
            </router-link>
            <v-spacer></v-spacer>

            <!-- Language selection -->
            <v-menu>
                <v-btn slot="activator" icon>
                    <v-img contain max-width="24" :src="languageFlagImageUrl"></v-img>
                </v-btn>
                <v-list>
                    <v-list-tile v-for="(item, index) in languageItems" :key="index"
                                 @click="changeLocale(item.languageCode)">
                        <img :src="getLanguageFlagImageUrlFromLanguageCode(item.languageCode)" width="24">
                        <v-list-tile-title class="mx-3">{{ item.title }}</v-list-tile-title>
                    </v-list-tile>
                </v-list>
            </v-menu>

            <!-- Drawer -->
            <v-btn icon @click.stop="rightDrawer = !rightDrawer">
                <v-icon>menu</v-icon>
            </v-btn>
        </v-toolbar>

        <!-- Content -->
        <v-content>
            <router-view class="pa-0"></router-view>
        </v-content>
        <v-navigation-drawer temporary right v-model="rightDrawer" fixed app>
            <v-list>
                <v-list-tile v-for="item in menuItems" :key="item.title" router :to="item.link">
                    <v-list-tile-action>
                        <v-icon class="accent--text">{{ item.icon }}</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content class="accent--text">{{ getMenuItemTitle(item.id ) }}</v-list-tile-content>
                </v-list-tile>
            </v-list>
        </v-navigation-drawer>

        <!-- Footer -->
        <v-footer id="footer">
            <v-layout column align-center>
                <v-flex>
                    <v-card class="elevation-0 transparent">
                        <v-card-title class="pa-0">
                            <div class="caption">&copy; 2014-2022 Arthur Vimond</div>
                        </v-card-title>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-footer>
    </v-app>
</template>

<script>
    import Home from './components/Home'
    import { supportedLocales } from './locale/supported-locales'

    export default {
        name: 'App',
        components: {
            Home
        },
        created() {
            const navLanguage = navigator.language.substring(0, 2)
            this.changeLocale(navLanguage)
        },
        data() {
            return {
                title: 'Arthur Vimond',
                language: this.$i18n.locale,
                languageItems: [{
                    title: 'English',
                    languageCode: 'en'
                }, {
                    title: 'Français',
                    languageCode: 'fr'
                }],
                rightDrawer: false,
                menuItems: [
                    {icon: 'work', id: 'work', link: '/#work'},
                    {icon: 'build', id: 'skills', link: '/#skills'},
                    {icon: 'face', id: 'about', link: '/#about'},
                    {icon: 'email', id: 'contact', link: '/#contact'}
                ]
            }
        },
        computed: {
            languageFlagImageUrl: function () {
                return this.getLanguageFlagImageUrlFromLanguageCode(this.language)
            }
        },
        methods: {
            getFlagCodeFromLanguageCode: function (languageCode) {
                if (languageCode === 'en') {
                    return 'us'
                } else if (languageCode === 'fr') {
                    return 'fr'
                }
            },
            getLanguageFlagImageUrlFromLanguageCode: function (languageCode) {
                const flagCode = this.getFlagCodeFromLanguageCode(languageCode)
                return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/' + flagCode + '.svg'
            },
            changeLocale(code) {
                const isSupported = this.isLocaleSupported(code)

                let supportedCode
                if (isSupported) {
                    supportedCode = code
                } else {
                    // Default locale
                    supportedCode = 'en'
                }

                this.$i18n.locale = supportedCode
                this.language = this.$i18n.locale
            },
            isLocaleSupported(code) {
                const found = supportedLocales.find(locale => locale === code)
                return found != undefined
            },
            getMenuItemTitle: function (id) {
                return this.$t(id + '.title')
            }
        }
    }
</script>

<style>

    a:link {
        text-decoration: none;
    }

    #footer {
        z-index: 1;
    }

</style>