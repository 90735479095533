<template>
    <v-layout>
        <v-flex>
            <v-card class="elevation-0 transparent mb-5">

                <!-- Title -->
                <v-card-title class="justify-center">
                    <div class="display-1">{{ $t('skills.title_long') }}</div>
                </v-card-title>

                <!-- List -->
                <v-container>
                    <v-layout row wrap justify-center>
                        <template v-for="(item, index) in skillItems">
                            <v-card class="ma-3 elevation-0 transparent" tile :key="index">
                                <v-img contain class="ma-3" :src="item.iconUrl" width="64" aspect-ratio="1"/>
                                <v-card-text class="mt-2 pa-0">
                                    <p class="text-xs-center">{{ item.name }}</p>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-layout>
                </v-container>
            </v-card>
        </v-flex>

    </v-layout>
</template>

<script>
    export default {
        name: 'Skills',
        created() {
            this.windowHeight = window.innerHeight
        },
        props: {},
        data() {
            return {
                skillItems: [{
                    name: 'Android',
                    iconUrl: require('@/assets/skills/svg/android.svg')
                }, {
                    name: 'iOS',
                    iconUrl: require('@/assets/skills/svg/apple-white.svg')
                }, {
                    name: 'Raspberry Pi',
                    iconUrl: require('@/assets/skills/svg/raspberry-pi.svg')
                }, {
                    name: 'Vue.js',
                    iconUrl: require('@/assets/skills/svg/vuejs.svg')
                }, {
                    name: 'Polymer',
                    iconUrl: require('@/assets/skills/svg/polymer.svg')
                }, {
                    name: 'Node.js',
                    iconUrl: require('@/assets/skills/svg/nodejs.svg')
                }, {
                    name: 'Firebase',
                    iconUrl: require('@/assets/skills/svg/firebase.svg')
                }, {
                    name: 'MongoDB',
                    iconUrl: require('@/assets/skills/svg/mongodb.svg')
                }, {
                    name: 'Git',
                    iconUrl: require('@/assets/skills/svg/git.svg')
                }, {
                    name: 'Travis CI',
                    iconUrl: require('@/assets/skills/png/travis-ci.png')
                }, {
                    name: 'ReactiveX',
                    iconUrl: require('@/assets/skills/svg/rx.svg')
                }, {
                    name: 'Unity',
                    iconUrl: require('@/assets/skills/svg/unity-white.svg')
                }, {
                    name: 'Vuforia',
                    iconUrl: require('@/assets/skills/png/vuforia.png')
                }, {
                    name: 'Processing',
                    iconUrl: require('@/assets/skills/svg/processing.svg')
                }, {
                    name: 'Arduino',
                    iconUrl: require('@/assets/skills/svg/arduino.svg')
                }, {
                    name: 'openFrameworks',
                    iconUrl: require('@/assets/skills/png/open-frameworks.png')
                }, {
                    name: 'Kotlin',
                    iconUrl: require('@/assets/skills/svg/kotlin.svg')
                }, {
                    name: 'Java',
                    iconUrl: require('@/assets/skills/svg/java.svg')
                }, {
                    name: 'Swift',
                    iconUrl: require('@/assets/skills/svg/swift.svg')
                }, {
                    name: 'C++',
                    iconUrl: require('@/assets/skills/svg/cpp.svg')
                }, {
                    name: 'HTML',
                    iconUrl: require('@/assets/skills/png/html5.png')
                }, {
                    name: 'CSS',
                    iconUrl: require('@/assets/skills/png/css3.png')
                }, {
                    name: 'JavaScript',
                    iconUrl: require('@/assets/skills/png/javascript.png')
                }, {
                    name: 'Photoshop',
                    iconUrl: require('@/assets/skills/svg/photoshop-cc.svg')
                }, {
                    name: 'Illustrator',
                    iconUrl: require('@/assets/skills/svg/illustrator-cc.svg')
                }, {
                    name: 'InDesign',
                    iconUrl: require('@/assets/skills/svg/indesign-cc.svg')
                }
                ]
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
