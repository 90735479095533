<template>
    <v-layout>
        <v-flex>
            <v-card class="elevation-0 transparent mb-5">
                <v-layout align-center justify-center>
                    <v-flex sm8 md6>

                        <!-- Title -->
                        <v-card-title class="justify-center">
                            <div class="display-1">{{ $t('about.title') }}</div>
                        </v-card-title>

                        <!-- Picture -->
                        <v-layout my-4 align-center justify-center>
                            <v-flex xs4 sm4 md4 lg3 xl2>
                                <v-img id="avatar" :src="images.profile.url" aspect-ratio="1"></v-img>
                            </v-flex>
                        </v-layout>

                        <!-- Bio -->
                        <v-card-text>
                            <p class="mx-2 title text-xs-center"> {{ $t('about.hi') }}</p>
                            <p class="mx-2 subheading text-xs-justify"> {{ $t('about.bio_1') }}</p>
                            <p class="mx-2 subheading text-xs-justify"> {{ $t('about.bio_2') }}</p>
                        </v-card-text>

                        <!-- Resume Button -->
                        <div class="mx-4">
                            <v-btn block outline @click="openResume">{{ $t('about.resume') }}</v-btn>
                        </div>

                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: 'About',
        created() {
            this.windowHeight = window.innerHeight
        },
        props: {},
        data() {
            return {
                images: {
                    profile: {
                        url: require('@/assets/about/profile.png')
                    }
                }
            }
        },
        methods: {
            openResume: function() {
                const resumeUrl = this.$t('about.resumeUrl')
                window.open(resumeUrl,'_blank')
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    #avatar {
        display: block;
    }


</style>
