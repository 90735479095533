import Vue from 'vue'
import {
    Vuetify,
    VApp,
    VCard,
    VCheckbox,
    VDialog,
    VNavigationDrawer,
    VFooter,
    VForm,
    VImg,
    VList,
    VBtn,
    VIcon,
    VGrid,
    VMenu,
    VProgressCircular,
    VSnackbar,
    VTextarea,
    VTextField,
    VToolbar,
    transitions
} from 'vuetify'
import 'vuetify/src/stylus/app.styl'
import { Resize } from 'vuetify/es5/directives'

Vue.use(Vuetify, {
    components: {
        VApp,
        VCard,
        VCheckbox,
        VDialog,
        VNavigationDrawer,
        VFooter,
        VForm,
        VImg,
        VList,
        VBtn,
        VIcon,
        VGrid,
        VMenu,
        VProgressCircular,
        VSnackbar,
        VTextarea,
        VTextField,
        VToolbar,
        transitions
    },
    directives: {
        Resize,
    },
    theme: {
        primary: '#FFC107',
        secondary: '#212121',
        accent: '#FFF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: "#000000"
    }
})
