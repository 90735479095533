export const messages = {
    en: {
        home: 'Home',
        mobile_developer: 'Mobile developer',
        work: {
            title: 'Work',
            oscleton: {
                description: "Ableton Live companion mobile application, monitoring Live set mixing changes in real-time."
            },
            oscleton_sdk: {
                description: "Core engine of the Oscleton mobile application, allowing a mobile device to communicate with Ableton Live. Open-source project on GitHub."
            },
            mojopro: {
                description: "Live video streaming application for mobile journalists, including a complete video editing solution. Working as Android and iOS developer at AVIWEST."
            },
            dmng_app: {
                description: "Live video streaming application (Android / iOS) for mobile journalists. Working as Android and iOS consulting developer for the AVIWEST company."
            },
            linqapp: {
                description: "Android application for language learners. Startup project created by Sebastian Ang. Worked as Android developer."
            },
            histoires_de_dire: {
                description: "Interactive stories Android application letting the user the ability to record and play the story's text. Project in collaboration with Zoé Aegerter and Félix Meunier."
            },
            book_in: {
                description: "Interactive paint based on Anne Crausaz's work exposed during the \"Ptits Bouquineurs 2014\" event in Rennes. Project in collaboration with Thomas Meghe."
            },
            air_step: {
                description: "Music application using the Leap Motion controller, including a step sequencer, a performer and an analog-style synthesizer."
            },
            resonances: {
                description: "Skills datavisualisation web project for the social network \"Résonances\" of Rennes 2 University. Project in collaboration with \"Les Macules et Conception\" designer team."
            },
            grav: {
                description: "Series of mobile applications recreating original works of 1968 GRAV artists' labyrinth. Project in collaboration with the students of Master Pro 2 Multimedia Creation and Management of Rennes 2 University, promotion 2013."
            },
            recollection: {
                description: "Augmented reality mobile application based on works catalog of Pierre Braun. Project in collaboration with the students of Master Pro 1 Multimedia Creation and Management of Rennes 2 University, promotion 2014. Exposed at the Lara Vincy galery in Paris."
            }
        },
        skills: {
            title: 'Skills',
            title_long: 'Skills and interests'
        },
        about: {
            title: 'About',
            hi: 'Hi!',
            bio_1: "My name is Arthur Vimond. I am a french 30 year-old developer living in Rennes. I first studied 3 years at the ESRA Bretagne school of cinema where I validated my certificate of ISTS (sound technician), then continued my Chinese studies for 3 years at Rennes 2 University and in the Mandarin Learning Center of the Chinese Culture University in Taipei.",
            bio_2: "In 2012, I started to learn programming by myself and since, I'm specializing in Mobile Development (Android & iOS). I love to create clean, well-structured and reactive code, following the best practices regarding architecture and design. I'm also interested in augmented and virtual reality, mobile games, creative coding and electronic music production.",
            resume: 'View my resume',
            resumeUrl: 'http://download.arthurvimond.fr/cv/en/CV Arthur Vimond.pdf'
        },
        contact: {
            title: 'Contact',
            form: {
                name: 'Name',
                email: 'Email',
                subject: 'Subject',
                message: 'Message',
                humanCheckOp: 'How much is 2 + 2?',
                send: 'Send'
            }
        },
        dialog: {
            sending_message: "Sending message…"
        },
        success: {
            message_sent: "Your message has been sent."
        },
        error: {
            required: 'Required',
            email_invalid: 'Invalid email',
            max_x_characters: '{0} characters maximum',
            message_not_sent: "An error occurred while sending the message. Please try again."
        }
    },
    fr: {
        home: 'Accueil',
        mobile_developer: 'Développeur mobile',
        work: {
            title: 'Travaux',
            oscleton: {
                description: "Application mobile pour Ableton Live, affichant les changements de mixage du Live set en temps réel."
            },
            oscleton_sdk: {
                description: "Moteur central de l'application mobile Oscleton, permettant à un appareil mobile de communiquer avec Ableton Live. Projet open source sur GitHub."
            },
            mojopro: {
                description: "Application de diffusion vidéo en direct pour journalistes mobiles, incluant une solution complète d'édition vidéo. Développeur Android et iOS chez AVIWEST."
            },
            dmng_app: {
                description: "Application de diffusion vidéo en direct (Android / iOS) pour journalistes mobiles. Développeur Android et iOS consultant pour la société AVIWEST."
            },
            linqapp: {
                description: "Application Android pour l'apprentissage de langues. Projet de startup créé par Sebastian Ang. Ayant travaillé en tant que développeur Android."
            },
            histoires_de_dire: {
                description: "Application Android d'histoires interactives permettant à l'utilisateur d'enregistrer et d'écouter le texte de l'histoire. Projet en collaboration avec Zoé Aegerter et Félix Meunier."
            },
            recollection: {
                description: "Application mobile de réalité augmentée basée sur un catalogue d'oeuvres de Pierre Braun. Projet en collaboration avec les étudiants du Master Pro 1 Création et Management Multimédia de l'Université Rennes 2, promotion 2014. Présenté à la galerie Lara Vincy à Paris."
            },
            grav: {
                description: "Série d'applications mobiles recréant des oeuvres originales du labyrinthe du GRAV de 1968. Projet en collaboration avec les étudiants du Master Pro 2 Création et Management Multimédia de l'Université Rennes 2, promotion 2013."
            },
            book_in: {
                description: "Tableau interactif basé sur une oeuvre d'Anne Crausaz présenté lors de l'évènement \"Les Ptits Bouquineurs 2014\" à Rennes. Projet en collaboration avec Thomas Meghe."
            },
            air_step: {
                description: "Application musicale utilisant le contrôleur Leap Motion, intégrant un séquenceur à pas, un performeur et un synthétiseur à modélisation analogique."
            },
            resonances: {
                description: "Projet web de datavisualisation de compétences pour le réseau social \"Résonances\" de l'Université Rennes 2. Projet en collaboration avec l'équipe \"Les Macules et Conception\"."
            }
        },
        skills: {
            title: 'Compétences',
            title_long: 'Compétences et intérêts'
        },
        about: {
            title: 'A propos',
            hi: 'Bonjour !',
            bio_1: "Je m'appelle Arthur Vimond, j'ai 30 ans. Je suis un développeur français vivant à Rennes. J'ai d'abord étudié trois ans à l'école d'audiovisuel ESRA Bretagne où j'ai validé mon certificat de fin d'études en tant que technicien son, puis continué mes études de chinois pendant trois ans à l'Université Rennes 2 et dans le centre de langue de l'Université de la Culture Chinoise à Taipei.",
            bio_2: "En 2012, j'ai commencé à apprendre la programmation par moi-même et depuis, je me spécialise dans le Développement Mobile (Android & iOS). J'aime créer un code clair, bien structuré et réactif, suivant les bonnes pratiques d'architecture et de design. Je m'intéresse également à la réalité virtuelle et augmentée, aux jeux mobiles, au code créatif et à la production de musique électronique.",
            resume: 'Voir mon CV',
            resumeUrl: 'http://download.arthurvimond.fr/cv/fr/CV Arthur Vimond.pdf'
        },
        contact: {
            title: 'Contact',
            form: {
                name: 'Nom',
                email: 'Email',
                subject: 'Sujet',
                message: 'Message',
                humanCheckOp: 'Combien font 2 + 2 ?',
                send: 'Envoyer'
            }
        },
        dialog: {
            sending_message: "Message en cours d'envoi…"
        },
        success: {
            message_sent: "Votre message a bien été envoyé."
        },
        error: {
            required: 'Requis',
            email_invalid: 'E-mail invalide',
            max_x_characters: '{0} caractères maximum',
            message_not_sent: "Une erreur est survenue pendant l'envoi du message. Veuillez réessayer."
        }
    }
}