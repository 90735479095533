<template>
    <v-layout column align-center>
        <v-flex xs12 sm4>
            <v-layout>
                <v-card class="elevation-0 transparent pa-3" :height="windowHeight * 0.95">
                    <v-container fluid fill-height>
                        <v-layout align-center>
                            <v-card class="elevation-0 transparent">
                                <router-link to="/#work">
                                    <v-img contain :height="images.avLogo.height" :src="images.avLogo.url"></v-img>
                                </router-link>
                                <v-card-text class="text-xs-center">
                                    <p class="display-2">Arthur Vimond</p>
                                    <p class="display-1">{{ $t('mobile_developer') }}</p>
                                    <social-links></social-links>
                                </v-card-text>
                            </v-card>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: 'Showcase',
        props: {},
        created() {
            this.windowHeight = window.innerHeight
        },
        data() {
            return {
                images: {
                    avLogo: {
                        url: require('@/assets/av-logo-white.png'),
                        height: 150
                    }
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
