<template>
    <v-container pa-0 fluid>
        <v-layout column>

            <showcase id="showcase"></showcase>

            <work id="work"></work>

            <skills id="skills"></skills>

            <about id="about"></about>

            <contact id="contact"></contact>

        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'Home'
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

</style>
