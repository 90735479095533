<template>
    <v-layout>
        <v-flex>
            <v-card class="elevation-0 transparent mt-3">
                <v-layout align-center justify-center row wrap>
                    <v-flex xs4 v-for="(item, index) in socialLinks" :key="index">
                        <a :href="item.linkUrl" target="_blank">
                            <v-img class="socialImg" :src="item.imageUrl" :width="socialImageSize"
                                   aspect-ratio="1"></v-img>
                        </a>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: 'About',
        created() {
            this.windowHeight = window.innerHeight
        },
        props: {},
        data() {
            return {
                socialImageSize: 32,
                socialLinks: [{
                    id: 'linkedin',
                    linkUrl: 'https://www.linkedin.com/in/arthurvimond/',
                    imageUrl: require('@/assets/logos/linkedin.svg')
                }, {
                    id: 'angellist',
                    linkUrl: 'https://angel.co/arthurvimond',
                    imageUrl: require('@/assets/logos/angellist.svg')
                }, {
                    id: 'github',
                    linkUrl: 'https://github.com/ArthurVimond',
                    imageUrl: require('@/assets/logos/github.svg')
                }
                ]
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .socialImg {
        margin: 0 auto;
        opacity: 0.6;
    }

    .socialImg:hover {
        opacity: 1;
    }

</style>
