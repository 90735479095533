import { render, staticRenderFns } from "./SocialLinks.vue?vue&type=template&id=ffbfccbe&scoped=true&"
import script from "./SocialLinks.vue?vue&type=script&lang=js&"
export * from "./SocialLinks.vue?vue&type=script&lang=js&"
import style0 from "./SocialLinks.vue?vue&type=style&index=0&id=ffbfccbe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffbfccbe",
  null
  
)

component.options.__file = "SocialLinks.vue"
export default component.exports