import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import { messages } from './locale/translations'

import Showcase from './components/home/Showcase.vue'
import Skills from './components/home/Skills.vue'
import Work from './components/home/Work.vue'
import About from './components/home/About.vue'
import Contact from './components/home/Contact.vue'
import SocialLinks from './components/home/SocialLinks.vue'

Vue.config.productionTip = false

// Components
Vue.component('showcase', Showcase)
Vue.component('skills', Skills)
Vue.component('work', Work)
Vue.component('about', About)
Vue.component('contact', Contact)
Vue.component('contact', Contact)
Vue.component('social-links', SocialLinks)

// Vue-resource
import 'vue-resource/dist/vue-resource'
import VueResource from 'vue-resource'
Vue.use(VueResource)

// Google Analytics
import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
    id: 'UA-115458067-3',
    router
})

// I18n
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'en', // set current locale
    messages, // set locale messages
})

// Router hook
router.afterEach(to => {
    // Reset hash
    router.push(to.path)
})

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount('#app')
