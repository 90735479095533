<template>
    <v-layout column align-center>
        <v-flex xs12 sm4>
            <v-card class="elevation-0 transparent mb-5">

                <!-- Title -->
                <v-card-title class="justify-center">
                    <div class="display-1">{{ $t('work.title') }}</div>
                </v-card-title>

                <!-- List -->
                <v-container>
                    <v-layout row wrap justify-center>
                        <template v-for="(item, index) in workItems">
                            <v-card class="ma-4" width="300" :key="index">

                                <a :key="index" :href="item.linkUrl" :target="item.newTab ? '_blank' : ''">
                                    <v-img contain :src="item.thumbnailUrl" aspect-ratio="1.33"/>
                                </a>

                                <v-card-title primary-title>
                                    <p class="headline ma-0">{{ item.title }}</p>
                                </v-card-title>

                                <v-card-text class="pt-0 mt-0 grey--text text--lighten-1">
                                    {{ getWorkDescription(item.id ) }}
                                </v-card-text>
                            </v-card>

                        </template>
                    </v-layout>
                </v-container>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: 'Work',
        created() {
            this.windowHeight = window.innerHeight
        },
        props: {},
        methods: {
            getWorkDescription: function (workId) {
                return this.$t('work.' + workId + '.description')
            }
        },
        data() {
            return {
                workItems: [{
                    id: 'oscleton',
                    title: 'Oscleton',
                    thumbnailUrl: require('@/assets/work/thumb/oscleton.png'),
                    linkUrl: 'https://oscleton.com',
                    newTab: true
                }, {
                    id: 'oscleton_sdk',
                    title: 'Oscleton SDK',
                    thumbnailUrl: require('@/assets/work/thumb/oscleton-sdk.png'),
                    linkUrl: 'https://sdk.oscleton.com',
                    newTab: true
                }, {
                    id: 'mojopro',
                    title: 'MoJoPro',
                    thumbnailUrl: require('@/assets/work/thumb/mojopro.png'),
                    linkUrl: 'work/mojopro',
                    newTab: false
                }, {
                    id: 'dmng_app',
                    title: 'DMNG APP',
                    thumbnailUrl: require('@/assets/work/thumb/dmng-app.png'),
                    linkUrl: 'work/dmng-app',
                    newTab: false
                }, {
                    id: 'linqapp',
                    title: 'Linqapp',
                    thumbnailUrl: require('@/assets/work/thumb/linqapp.png'),
                    linkUrl: 'https://www.youtube.com/watch?v=280ivszU6DE',
                    newTab: true
                }, {
                    id: 'histoires_de_dire',
                    title: 'Histoires de dire',
                    thumbnailUrl: require('@/assets/work/thumb/histoires-de-dire.png'),
                    linkUrl: 'work/histoires-de-dire',
                    newTab: false
                }, {
                    id: 'recollection',
                    title: "Recollection",
                    thumbnailUrl: require('@/assets/work/thumb/recollection.jpg'),
                    linkUrl: 'http://www.pierre-braun.fr/appli-lara-vincy.html',
                    newTab: true
                }, {
                    id: 'grav',
                    title: "GRAV",
                    thumbnailUrl: require('@/assets/work/thumb/grav.jpg'),
                    linkUrl: 'https://oin.hypotheses.org/419',
                    newTab: true
                }, {
                    id: 'book_in',
                    title: "Book'in",
                    thumbnailUrl: require('@/assets/work/thumb/book-in.jpg'),
                    linkUrl: 'work/bookin',
                    newTab: false
                }, {
                    id: 'air_step',
                    title: "AirStep",
                    thumbnailUrl: require('@/assets/work/thumb/airstep.jpg'),
                    linkUrl: 'work/airstep',
                    newTab: false
                }, {
                    id: 'resonances',
                    title: "Résonances",
                    thumbnailUrl: require('@/assets/work/thumb/resonances.jpg'),
                    linkUrl: 'work/resonances',
                    newTab: false
                }
                ]
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
